@import ./td-basic/size
#projects
    position: relative
    .grid
        display: grid
        grid-gap: 10px
        .grid-item
            &:nth-child(1)
                grid-area: myArea1
            &:nth-child(2)
                grid-area: myArea2
            &:nth-child(3)
                grid-area: myArea3
            &:nth-child(4)
                grid-area: myArea4
            &:nth-child(5)
                grid-area: myArea5
            &:nth-child(6)
                grid-area: myArea6
            &:nth-child(7)
                grid-area: myArea7
            &:nth-child(8)
                grid-area: myArea8
            &:nth-child(9)
                grid-area: myArea9
            &:nth-child(10)
                grid-area: myArea10
            &:nth-child(11)
                grid-area: myArea11
            &:nth-child(12)
                grid-area: myArea12
            &:nth-child(13)
                grid-area: myArea13
            &:nth-child(14)
                grid-area: myArea14
            &:nth-child(15)
                grid-area: myArea15
            &:nth-child(16)
                grid-area: myArea16
            &:nth-child(17)
                grid-area: myArea17
            &:nth-child(18)
                grid-area: myArea18

            &:nth-child(19)
                grid-area: myArea19
            &:nth-child(20)
                grid-area: myArea20
            &:nth-child(21)
                grid-area: myArea21
            &:nth-child(22)
                grid-area: myArea22
            &:nth-child(23)
                grid-area: myArea23
            &:nth-child(24)
                grid-area: myArea24

            &:nth-child(even)
                & *
                    text-shadow: 0 0 $size-m #000
                a
                    color: white
                    &:hover
                        .fake-bkg
                            background-color: black
                    .fake-bkg
                        background-color: #444
            &:nth-child(odd)
                & *
                    text-shadow: 0 0 $size-m #fff
                a
                    color: black
                    &:hover
                        .fake-bkg
                            background-color: white
                    .fake-bkg
                        background-color: #ddd
            a
                position: relative
                z-index: 0
                display: flex
                justify-content: center
                align-items: center
                flex-direction: column
                height: 100%
                overflow: hidden
                background: transparent !important
                border-radius: $size-m
                text-align: center
                padding: $size-xs
                border: 1px solid #ccc
                transition: background-color 400ms
                .fake-bkg
                    transition: background-color .5s
                    position: absolute
                    width: 120%
                    height: 120%
                    background-blend-mode: overlay
                    top: -10%
                    left: -10%
                    background-repeat: no-repeat
                    background-size: cover
                    background-position: center center
                    filter: blur(3px)
                    z-index: -1
                &:hover
                    text-decoration: none
                    background-color: #111
                    // img
                        // filter: brightness(120%)
                    // img
                    //     width: 100%
                .title
                    font-size: 1.4rem
                    margin-top: $size-m
                .description
                    font-size: 0.9rem
                img
                    transition: width 400ms
                    width: 90%
                    box-shadow: $size-s $size-s $size-xl #000
                    border: 1px solid #333
                    
